
import {
  Component,
  Emit,
  Inject,
  Model,
  Prop,
  Provide,
  Vue,
  Watch,
} from "vue-property-decorator";

@Component({
  props: {},
  components: {},
})
export default class Paging extends Vue {
  //init data
  @Prop({ default: false })
  loading?: any;

  @Prop({
    default: () => {
      return {
        pageNum: 1,
        pageSize: 20,
        total: 0,
      };
    },
  })
  options?: any;
  //computed
  //get example() {}

  //methods
  // 每页数据数改变
  @Emit("getList")
  handleSizeChange(_val:any) {
    this.options.pageNum = 1;
    this.options.pageSize = _val;
  }
  // 页码改变
  @Emit("getList")
  handleCurrentChange(_val:any) {
    this.options.pageNum = _val;
  }
  //lifecycle
  mounted() {}
}


import {
  Component,
  Emit,
  Inject,
  Model,
  Prop,
  Provide,
  Vue,
  Watch,
} from "vue-property-decorator";

@Component({
  props: {},
  components: {},
})
export default class Breadcrumbs extends Vue {
  //init data

 @Prop({ default:null })
  options?: any;

back(name:any){
  if(!name) return;
  if((typeof name === 'function')){
    name();
    return;
  }
  this.$router.push({ name: name }).catch(() => {});
}
  mounted() {

  }
}


import Paging from "@/components/Paging.vue";
import {
  Component,
  Emit,
  Inject,
  Model,
  Prop,
  Provide,
  Vue,
  Watch,
} from "vue-property-decorator";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
@Component({
  name: "DeleteNotice",
  props: {},
  components: { Paging, Breadcrumbs },
})

export default class DeleteNotice extends Vue {
  zzlApi: any;
  tableData: any = [];
  idArr: any = [];
  conditions: any = {
    name: "",
    pageNum: 1,
    pageSize: 20,
    total:0
  };
  selectionChange(selectItems: any) {  // 批量选择每一行的id
    this.idArr = selectItems.map((m: any) => {
      return m.id;
    });
  }
  resetAction() {
    this.conditions = {
      name: "",
      pageNum: 1,
      pageSize: 20,
      total:0
    };
    this.tableData = [];
  }
  searchAction() {
    this.conditions.pageNum = 1;
    this.getList();
  }
  async deleteMore()
  {
    if (!this.idArr.length) {
        this.$message.warning(`请至少选择一条数据`);
        return;
      }
     this.$confirm("此操作将永久删除该批数据, 是否继续?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(async () => {
        const res = await this.zzlApi.deleteNotice.Delete({ids:this.idArr }, null);
        if (res.code == 0) {
          this.getList();
          this.$message.success("删除成功");
        } else {
          this.$message.error(`删除失败：${res.msg}`);
        }
      })
      .catch(() => {
        this.$message.info("已取消");
      });

  }
  async deleteItem(id: any) { // 批量删除及单个删除
    this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(async () => {
        const res = await this.zzlApi.deleteNotice.Delete({ids:[id] }, null);
        if (res.code == 0) {
          this.getList();
          this.$message.success("删除成功");
        } else {
          this.$message.error(`删除失败：${res.msg}`);
        }
      })
      .catch(() => {
        this.$message.info("已取消");
      });
  }
  /* 查询课程列表 */
  async getList() {
    const res = await this.zzlApi.deleteNotice.Getlist({ ...this.conditions },      null    );
    if (res.code === 0) {
      this.tableData = res.data.list;

      this.conditions.total = Number(res.data.count);
    } else {
      this.$message.error(res.msg.msg);
    }
  }
  activated() {
  }
  mounted() {
    this.getList();
  }
}
